import { get, post } from './axios'

export default {
  // 获取企业下所有企业角色
  getAllUserBusinessRole(param) {
    return get('/user/businessRole/getAllUserBusinessRole', param)
  },
  // 获取企业角色下员工信息
  getUserBusinessRoleStaff(param) {
    return get('/user/businessRole/getUserBusinessRoleStaff', param)
  },
  // 获取企业关系角色
  getComRelationRole(param) {
    return get('/user/comRelationRole/getComRelationRole', param)
  },
  // 获取当前部门所有用户首字母
  getCurrentDepartmentUserFristLetter(param) {
    return get('/user/addressBook/getCurrentDepartmentUserFristLetter', param)
  },
  // 获取当前企业的企业架构
  getCurrentComFramework(param) {
    return get('/user/addressBook/getCurrentComFramework', param)
  },
  // 获取外部联系人
  getExternalContacts(param) {
    return post('/user/addressBook/getExternalContacts', param)
  },
  // 获取当前企业下所有部门
  getAllDepartment(param) {
    return get('/user/addressBook/getAllDepartment', param)
  },
  // 获取内部联系人/离职成员
  getInsideContacts(param) {
    return post('/user/addressBook/getInsideContacts', param)
  },
}
