<template>
  <div class="tab-box">
    <van-checkbox-group v-model="radioFirendIdSeveral">
      <van-tabs v-model="active" scrollspy sticky :ellipsis="false">
        <van-tab
          v-for="(item, index) in departmentList"
          :key="index"
          class="tab-content"
        >
          <div class="tab-title" slot="title" @click="handleTab(item, index)">
            {{ item.name }}
          </div>
          <van-collapse
            v-model="activeNames"
            @change="handleClass(item)"
            :border="false"
          >
            <van-collapse-item
              :title="item.name"
              :name="`${index}`"
              :border="false"
            >
              <div
                v-for="(item1, index1) in item.depList"
                :key="index1"
                class="radio-box"
              >
                <div class="left">
                  <div class="item-radio">
                    <van-checkbox
                      :name="item1.uid"
                      @click="handleRadioChange($event, item, item1)"
                    ></van-checkbox>
                  </div>
                  <div class="head-img">
                    <img
                      :src="
                        item1.headImg ||
                          require('@/assets/imgs/default_img.png')
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div class="right">
                  <div class="name">{{ item1.name }}</div>
                  <div class="phone">{{ item1.telephone }}</div>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-tab>
      </van-tabs>
    </van-checkbox-group>
    <div class="tab-icon">
      <img src="@/assets/imgs/形状@2x1.png" alt="" />
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      departmentList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        // checkbox选中值
        radioFirendIdSeveral: [],
        active: '',
        activeNames: ['0'],
      }
    },
    created() {},
    computed: {},
    watch: {
      departmentList: {
        handler(val) {
          this.departmentList = val
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      handleClose() {
        this.$emit('onClose')
      },
      handleTab(val, index) {
        this.activeNames = [`${index}`]
        this.$emit('callbackRefresh', val.id)
      },
      handleClass(val) {
        this.$emit('callbackRefresh', val.id)
      },
      // radio事件选择好友 dep 部门 val 部门下的人
      handleRadioChange(event, dep, val) {
        const personObj = []
        console.log(this.departmentList)
        this.radioFirendIdSeveral.forEach((item, index) => {
          this.departmentList[0].depList.forEach((item1, index1) => {
            if (item === item1.uid) {
              personObj.push(item1)
            }
          })
        })
        this.$emit('handleCheckboxChange', personObj)
      },
    },
  }
</script>
<style lang="less" scoped>
  .cur-text {
    font-size: 12px;
    font-weight: 400;
    color: #8a9ab4;
  }
  .cur-padding {
    padding: 0 16px;
  }
  .tab-box {
    padding-bottom: 82px;
    position: relative;
    &:extend(.cur-padding);
    /deep/.van-tab {
      display: inline;
      padding: 0 13px;
      line-height: 48px;
      text-align: center;
    }
    .firend-letter {
      width: 100%;
      height: 18px;
      background: #f4f5f6;
      line-height: 18px;
      &:extend(.cur-text);
      &:extend(.cur-padding);
    }
    // .van-radio-group {
    //   padding: 0 16px;
    // }
    .radio-box {
      height: 80px;
      display: flex;
      align-items: center;
      padding: 16px 0;
      box-shadow: 0px -1px 0px 0px #f5f5f5 inset;
      .left {
        display: flex;
        align-items: center;
        .head-img {
          margin-left: 16px;
          img {
            width: 44px;
            height: 44px;
            border-radius: 22px;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        .name {
          font-size: 14px;
          color: #111a34;
        }
        .phone {
          font-size: 14px;
          color: #677283;
          margin-top: 3px;
        }
      }
    }
    /deep/.van-cell {
      height: 42px;
      background: rgba(22, 118, 255, 0.05);
      border-radius: 2px;
      margin-top: 8px;
    }
    /deep/.van-tabs__wrap {
      width: calc(100% - 35px);
    }
    /deep/.van-tabs__nav {
      padding: 0;
      padding-bottom: 15px;
      display: inline-flex;
    }
    /deep/.van-sticky--fixed {
      padding: 0 16px;
      background: #fff;
      .van-tabs__wrap {
        width: 100%;
      }
    }
    .tab-icon {
      position: absolute;
      top: 7px;
      right: 18px;
      &::before {
        content: '';
        top: 8px;
        right: 26px;
        display: inline-block;
        position: absolute;
        width: 1px;
        height: 20px;
        background: #e2e4ea;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
    /deep/.tab-title {
      display: inline-flex;
    }
  }
</style>
