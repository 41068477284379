<template>
  <div class="see-firend-popup">
    <van-popup v-model="show" @close="handleClose">
      <div class="content">
        <p class="title">已选联系人</p>
        <div v-for="(item, index) in peopleList" :key="index" class="radio-box">
          <div class="left">
            <div class="head-img">
              <img
                :src="item.headImg || require('@/assets/imgs/default_img.png')"
                alt=""
              />
            </div>
          </div>
          <div class="right">
            <div class="name">{{ item.name }}</div>
            <div class="phone">{{ item.telephone }}</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
  export default {
    props: {
      seeFirendShow: {
        type: Boolean,
        default: false,
      },
      peopleList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        show: false,
      }
    },
    computed: {},
    watch: {
      seeFirendShow() {
        this.show = this.seeFirendShow
      },
    },
    methods: {
      handleClose() {
        this.$emit('onClose')
      },
    },
  }
</script>
<style lang="less" scoped>
  .cur-text {
    font-size: 12px;
    font-weight: 400;
    color: #8a9ab4;
  }
  .cur-padding {
    padding: 0 16px;
  }
  .van-popup {
    border-radius: 6px;
  }
  .see-firend-popup {
    .content {
      width: 306px;
      height: 436px;
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 6px;
      .title {
        text-align: center;
        font-size: 18px;
        color: #111a34;
        height: 66px;
        line-height: 66px;
      }
    }
    .radio-box {
      height: 80px;
      display: flex;
      align-items: center;
      padding: 16px 0;
      box-shadow: 0px -1px 0px 0px #f5f5f5 inset;
      .left {
        display: flex;
        align-items: center;
        .head-img {
          margin-left: 16px;
          img {
            width: 44px;
            height: 44px;
            border-radius: 22px;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        .name {
          font-size: 14px;
        }
        .phone {
          font-size: 14px;
          color: #677283;
          margin-top: 3px;
        }
      }
    }
  }
</style>
