<template>
  <div v-if="show" class="contacts-friend">
    <Header title="选择联系人" @onClose="onClose" v-if="showHeader" />
    <div class="search-input">
      <van-field
        :class="formData.param ? 'search' : ''"
        v-model="formData.param"
        placeholder="请输入姓名/手机号"
      >
        <template #right-icon>
          <van-icon
            :name="require('../../assets/imgs/search_img.png')"
            @click="handleSearch"
          />
        </template>
      </van-field>
      <div v-if="formData.param" class="cell-cancel">
        <span @click="handleCancelSearch">取消</span>
      </div>
    </div>
    <div class="tab-box">
      <SelectRadio
        v-if="type"
        :show-company-name="showCompanyName"
        :departmentList="departmentList"
        @callbackRefresh="handleRefresh"
        @callbackRadioChange="handleRadioChange"
      />
      <SelectCheckbox
        v-else
        :departmentList="departmentList"
        @callbackRefresh="handleRefresh"
        @handleCheckboxChange="handleCheckboxChange"
      />
      <div v-if="rolePeopleList.length" class="button-box">
        <div class="button-all" @click="seeFirendShow = true">
          查看已选({{ rolePeopleList.length }})
        </div>
        <div class="button-confirm">
          <van-button type="info" @click="handleConfirm">确定</van-button>
        </div>
      </div>
    </div>
    <SeeFirendPopup
      :seeFirendShow="seeFirendShow"
      :peopleList="rolePeopleList"
      @onClose="seeFirendShow = false"
    />
  </div>
</template>
<script>
  // 个人身份用 选外部企业
  import { mapState, mapGetters } from 'vuex'
  import Api from '@/api/businessRole'
  import Header from './components/header.vue'
  import SeeFirendPopup from './components/seeFirendPopup.vue'
  import SelectRadio from './components/selectRadio.vue'
  import SelectCheckbox from './components/selectCheckbox.vue'

  export default {
    props: {
      type: {
        // 0多选 1单选
        type: Number,
        default: () => 1,
      },
      show: {
        type: Boolean,
        default: () => false,
      },
      // 展示 header 栏
      showHeader: {
        type: Boolean,
        default: true,
      },

      // 展示公司名
      showCompanyName: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      SeeFirendPopup,
      SelectRadio,
      SelectCheckbox,
      Header,
    },

    data() {
      return {
        seeFirendShow: false,
        formData: {
          fristLetter: '', // 检索条件 姓名首字母
          pageNum: 1,
          pageSize: 9999,
          param: '', // 检索条件 姓名/手机号
          // 0-获取个人身份 1-获取企业身份 不传-都获取
          type: 1, // 1 在职 0 离职

          departId: '',
        },
        total: 0,
        departmentList: [],
        activeNames: ['0'],
        active: '',
        contactsList: [],
        // radio选中值
        radioFirendId: '',
        depValue: {},
        depFriendValue: {},
        // 选中值人员列表
        rolePeopleList: [],
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    async created() {
      // 获取所有部门
      await this.getAllDepartment()
      // 获取部门下的人
      await this.getInsideContacts()
      // await this.getCurrentDepartmentUserFristLetter()
      // await this.getFriendList()
    },
    methods: {
      getAllDepartment() {
        return Api.getAllDepartment().then(res => {
          this.departmentList = res
          this.departmentList.unshift({
            children: [],
            name: '全部',
            id: '',
            parentId: '',
          })
        })
      },
      getInsideContacts() {
        return Api.getInsideContacts(this.formData).then(res => {
          this.departmentList.forEach((item, index) => {
            if (item.id === this.formData.departId) {
              this.$set(item, 'depList', res.rowsList)
            }
            // if (this.formData.param) {
            //   res.rowsList.forEach((item1, index1) => {
            //     if (item.id === item1.departmentId) {
            //       this.departmentList = item
            //       item.depList = res.rowsList
            //     }
            //   })
            // }
          })
        })
      },

      // 获取当前部门所有用户首字母
      getCurrentDepartmentUserFristLetter() {
        return Api.getCurrentDepartmentUserFristLetter().then(res => {
          this.letterList = res
        })
      },
      // 搜索
      handleSearch() {
        this.getInsideContacts()
      },
      handleCancelSearch() {
        this.formData.param = ''
        this.getInsideContacts()
      },

      handleConfirm() {
        if (this.type) {
          this.$emit('roleCallback', this.depValue, this.depFriendValue)
        } else {
          this.$emit('roleCallback', this.rolePeopleList)
        }
        this.$emit('onConfirmClose')
      },
      handleRefresh(id) {
        this.formData.departId = id
        this.getInsideContacts()
      },
      handleRadioChange(dep, val, radioFirendId) {
        this.rolePeopleList = []
        this.depValue = dep
        this.depFriendValue = val
        this.rolePeopleList.push(val)
        this.radioFirendId = radioFirendId
      },
      handleCheckboxChange(valSeveral) {
        this.rolePeopleList = valSeveral
      },
      onClose() {
        this.rolePeopleList = []
        this.depValue = undefined
        this.depFriendValue = undefined
        this.radioFirendId = undefined
        this.$emit('onClose')
      },
    },
  }
</script>
<style lang="less" scoped>
  .cur-text {
    font-size: 12px;
    font-weight: 400;
    color: #8a9ab4;
  }
  .cur-padding {
    padding: 0 16px;
  }
  .contacts-friend {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 2000;
    overflow-y: scroll;
    overflow-x: hidden;

    .search-input {
      display: flex;
      align-items: center;
      .van-cell {
        &::after {
          border-bottom: 0;
        }
      }
      .search {
        padding-right: 0;
      }
      /deep/.van-cell__value {
        height: 32px;
        line-height: 32px;
        background: #f4f5f6;
        border-radius: 16px;
        &:extend(.cur-padding);
        .van-field__right-icon {
          img {
            margin-bottom: 3px;
          }
        }
      }
      .cell-cancel {
        width: 66px;
        height: 32px;
        font-size: 16px;
        line-height: 32px;
        padding-right: 16px;
        text-align: right;
      }
    }

    .tab-box-bottom {
      padding-bottom: 100px;
    }
    .button-box {
      width: 100%;
      height: 70px;
      background: #ffffff;
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      &:extend(.cur-padding);
      .button-all {
        font-size: 15px;
        color: #1676ff;
      }
      .button-confirm {
        .van-button {
          width: 136px;
          height: 46px;
          background: #1676ff;
          border-radius: 25px;
          font-size: 18px;
        }
      }
    }
    .letter-sort {
      width: 20px;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: 0;
      color: #677283;
      margin-top: 165px;
    }
  }
</style>
